import React from 'react';

import * as style from './Content.module.css';
import { Heading, Margins } from '../../../components';
import { useTranslation } from '../../../hooks';

const Content = ({
  LOCATION,
  CONTACTS,
  FREE_PARKING,
  PARKING_INFO,
  BUS_INFO,
  MORE_INFO,
}) => {
  const { t } = useTranslation();

  return (
    <div className={style['content']}>
      <Margins size="05x">
        <Heading size="h2" visualLevel="h5" highlight weight="bold">
          {LOCATION}
        </Heading>
        {CONTACTS.map((contact, index) => (
          <p key={index}>{contact}</p>
        ))}
        <p>
          {t('CONTACT.EMAIL')}{' '}
          <a href={`mailto:${t('CONTACT.EMAIL_ADDRESS')}`}>
            {t('CONTACT.EMAIL_ADDRESS')}
          </a>
        </p>
        <p>{t('CONTACT.TEXT_1')}</p>
        {MORE_INFO ? <p>{MORE_INFO}</p> : null}
      </Margins>
      <Margins size="05x">
        <Heading size="h2" visualLevel="h5" highlight weight="bold">
          {FREE_PARKING}
        </Heading>
        <p>{PARKING_INFO}</p>
        {BUS_INFO && (
          <p>
            {'('}
            <a href={BUS_INFO} target="_blank" rel="noopener noreferrer">
              {BUS_INFO}
            </a>
            {')'}
          </p>
        )}
      </Margins>
    </div>
  );
};

export default Content;
