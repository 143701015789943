export function createMapScript() {
  return new Promise((resolve) => {
    if (window.google?.maps) {
      return resolve();
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_API_KEY}&callback=initMap`;
    script.defer = true;

    window.initMap = function () {
      resolve();
    };

    document.body.appendChild(script);
  });
}
