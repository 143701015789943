import React from 'react';

import Layout from '../containers/Layout/Layout';
import { Container, Margins, GoogleMaps, Seo } from '../components';
import { Content } from '../containers/Contact';
import { useTranslation } from '../hooks';
import { createMapScript } from '../components/GoogleMaps/map';

const Contact = ({ pageContext: { currentPath } }) => {
  const { t, locale, translations } = useTranslation();
  const [mapInitialized, setMapInitialized] = React.useState(false);

  function getLocation(location) {
    return {
      lat: Number(location.LAT),
      lng: Number(location.LNG),
    };
  }

  React.useEffect(() => {
    async function initMapScript() {
      await createMapScript();

      setMapInitialized(true);
    }

    initMapScript();
  }, []);

  return (
    <Layout>
      <Seo
        title={t('SEO.CONTACT.TITLE')}
        location={currentPath}
        lang={locale}
      />
      <Container tag="section">
        <Margins size="3x">
          {translations.CONTACT.LOCATIONS.map((location) => (
            <Margins size="2x" key={location.NAME}>
              {mapInitialized && (
                <GoogleMaps location={getLocation(location)} />
              )}
              <Content {...location} />
            </Margins>
          ))}
        </Margins>
      </Container>
    </Layout>
  );
};

export default Contact;
